import React from 'react';
import Layout from '../components/layoutLightEN';
import SEO from '../components/seo';
import Contact from '../containers/contactEN';

type ContactPageProps = {};

const ContactPage: React.FunctionComponent<ContactPageProps> = () => {
  return (
    <Layout>
      <SEO
        title="Contact the Sifrein Gallery"
        description="Sifrein Paris gallery contact form"
      />

      <Contact />
    </Layout>
  );
};

export default ContactPage;
